<template>
  <MDBRow tag="footer" class="text-light">
    <MDBCol>
      <MDBContainer>
        <MDBRow class="py-3">
          <ColumnFooter title="Contact">
            <a href="mailto:hello@lukenelson.uk">hello@lukenelson.uk</a>
          </ColumnFooter>
          <ColumnFooter title="Social">
            <SocialIcons />
          </ColumnFooter>
          <ColumnFooter title="Development">
            Check out my
            <NuxtLink to="https://lukenelson.dev">development site</NuxtLink>!
          </ColumnFooter>
        </MDBRow>
      </MDBContainer>
    </MDBCol>
  </MDBRow>
</template>

<script setup lang="ts">
import { MDBRow, MDBCol, MDBContainer } from "mdb-vue-ui-kit";
</script>

<style lang="scss">
@use "../assets/styles/variables" as v;

footer {
  background-color: v.$grey;
  a[href] {
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-color: rgba(v.$primary, 0.65);
  }
}
</style>
